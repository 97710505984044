import React, { useState, useEffect, useMemo } from 'react';
import api from 'api';
import { ImportTemplateDetail, ImportTemplateBody, ImportTemplateTypeEnum } from 'models/importTemplates';
import Form from 'components/commons/form/Form';
import FormInput from 'components/commons/form/FormInput';
import ActionButton from 'components/commons/buttons/ActionButton';
import FormDropdown from 'components/commons/form/FormDropdown';
import { Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import DropZone from 'components/commons/DropZone';
import { FILE_TYPE } from 'constants/utilities/devTools';
import Loader from 'components/commons/modal/Loader';
import urlUtils from 'utilities/urlUtils';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { IMPORT_TEMPLATE_TYPE_LABEL } from 'constants/app/importTemplates';
import ImportTemplatePackageSettings from './ImportTemplateSettings/ImportTemplatePackageSettings';
import FormApiSearchableDropdown from 'components/commons/form/FormApiSearchableSelect';
import ImportTemplateItemMultiPackageSettings from './ImportTemplateSettings/ImportTemplateItemMultiPackageSettings';
import ImportTemplateStepMultiPackageSettings from './ImportTemplateSettings/ImportTemplateStepMultiPackageSettings';

interface ImportTemplateFormData extends Omit<ImportTemplateBody, 'id' | 'path' > {
  preassigned_url?: string;
}

interface ImportTemplateFormProps {
  id: string | null;
}


const SETTINGS_COMPONENT_CLASS = {
  [ImportTemplateTypeEnum.NONE]: undefined,
  [ImportTemplateTypeEnum.PROJECT]: undefined,
  [ImportTemplateTypeEnum.PACKAGE_DOC]: ImportTemplatePackageSettings,
  [ImportTemplateTypeEnum.PACKAGE_EXP]: ImportTemplatePackageSettings,
  [ImportTemplateTypeEnum.PACKAGE_LAB]: ImportTemplatePackageSettings,
  [ImportTemplateTypeEnum.PACKAGE_NOI]: ImportTemplatePackageSettings,
  [ImportTemplateTypeEnum.PACKAGE_TPI]: ImportTemplatePackageSettings,
  [ImportTemplateTypeEnum.ITEM]: undefined,
  [ImportTemplateTypeEnum.STEP]: undefined,
  [ImportTemplateTypeEnum.ITEM_MULTIPACKAGE]: ImportTemplateItemMultiPackageSettings,
  [ImportTemplateTypeEnum.STEP_MULTIPACKAGE]: ImportTemplateStepMultiPackageSettings,
}

function ImportTemplateForm({ id }: ImportTemplateFormProps) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ImportTemplateFormData>({
    name: '',
    version: '',
    type: ImportTemplateTypeEnum.NONE,
    mime_type: '',
    preassigned_url: '',
    settings: {},
    account: undefined
  });
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewTemplate, setisNewTemplate] = useState<boolean>(true);
  const [showFileExt, setShowFileExt] = useState<boolean>(false);

  useEffect(() => {
    //in caso di nuovo inserimento arriva l'id ':id'
    if (id && id !== ':id') {
      setisNewTemplate(false);
      setShowFileExt(true);
      api.importTemplates.get(id, false).then((res) => {
        const { preassigned_url, ...rest } = res;
        setFormData({
          ...rest,
        });
      });
    }
  }, [id]);

  
  const setSettingsFormData = (name: string, val: any) => 
    setFormData((prev) => ({ ...prev, settings: {...prev.settings, [name]: val}  }));
  const SettingsCmpClass = useMemo(() => {
    const CmpClass = SETTINGS_COMPONENT_CLASS[formData?.type ?? ImportTemplateTypeEnum.NONE];
    if(CmpClass == null) {
      return undefined;
    }
    return CmpClass;
  }, [formData?.type])

  const dropdownChangeHandler = (name: string, event: any) => {
    setFormData((prevData) => ({ ...prevData, [name]: event }));
  };

  const handleFileChange = (files: any[]) => {
    if (files && files.length > 0) {
      setFile(files[0]);
      setShowFileExt(true);
      formData.file_extension = files[0].name.split('.').pop();
      formData.name = files[0].name.replace('.' + formData.file_extension, '');
    } else {
      setShowFileExt(false);
    }
  };

  const formDataChangeHandler = (name: string, value: any) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = { ...formData, upload_base64: '' };
    delete payload.preassigned_url;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        let base64Data = '';
        let mimeType = '';
        if (reader.result && typeof reader.result === 'string') {
          const resultParts = reader.result.split(',');
          mimeType = resultParts[0].split(':')[1].split(';')[0];
          base64Data = resultParts[1];
        }
        payload.mime_type = mimeType;
        payload.upload_base64 = base64Data;
        payload.file_extension = formData.file_extension;

        await submitData({ ...payload });
        setIsLoading(false);
      };
      reader.readAsDataURL(file);
    } else {
      submitData({ ...payload });
      setIsLoading(false);
    }
  };

  const isPayloadValid = (payload: { [x: string]: string }) => {
    const fieldsToValidate = ['name', 'type'];
    if (isNewTemplate) {
      fieldsToValidate.push('mime_type');
      fieldsToValidate.push('upload_base64');
    }
    for (const field of fieldsToValidate) {
      console.log('payload: ', payload);
      if (payload[field].trim() == '') {
        return false;
      }
    }
    return true;
  };

  const submitData = async (payload: Omit<ImportTemplateFormData, 'preassigned_url'>) => {
    const apiPayload: ImportTemplateBody = {
      ...payload,
      upload_base64: payload.upload_base64 || ''
    };
    const  {account, ...payloadWithoutAccount } = payload;
    if (!isPayloadValid(payloadWithoutAccount)) {
      console.error('Validation failed: fields cannot be empty.');
      return;
    }
    try {
      if (id && id !== ':id') {
        setIsLoading(true);
        //await api.importTemplates.putTemplate(apiPayload, id);
        await api.importTemplates.putTemplate(apiPayload, id).then((res) => {
          navigate(urlUtils.templates.imports.detail(res.id));
        });
      } else {
        setIsLoading(true);
        //await api.importTemplates.addTemplate(apiPayload);
        await api.importTemplates.addTemplate(apiPayload).then((res) => {
          //setIsLoading(false);
          navigate(urlUtils.templates.imports.detail(res.id));
        });
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const handleDownload = async () => {
    if (!id || id === ':id') return; // Early exit if no ID is provided
    setIsLoading(true);
    try {
      const response = await api.importTemplates.get(id, true);
      if (response.preassigned_url) {
        window.location.href = response.preassigned_url; // Redirect to download link
      } else {
        console.error('Download URL is missing');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner animation="border" variant="primary" style={{ maxHeight: '4rem', maxWidth: '4rem' }}></Spinner>
      </div>
    );
  }
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '25px', marginBottom: '50px' }}>
        <div style={{ width: '40%', marginRight: '10%' }}>
          <div>
            <Form>
              <FormInput
                inputGroupSuffix={
                  <InputGroup.Text id="basic-addon2" className="form-input-text-addon">
                    {showFileExt ? formData.file_extension : ''}
                  </InputGroup.Text>
                }
                label="Name"
                value={formData.name}
                onChange={(e) => formDataChangeHandler('name', e.target.value)}
                disabled={false}
              />

              <FormDropdown label="Type" defaultValue={IMPORT_TEMPLATE_TYPE_LABEL[formData.type]} onSelect={(e) => dropdownChangeHandler('type', e)} disabled={false}>
                {Object.keys(IMPORT_TEMPLATE_TYPE_LABEL).map(k => <Dropdown.Item key={k} eventKey={k}>{IMPORT_TEMPLATE_TYPE_LABEL[k as ImportTemplateTypeEnum]}</Dropdown.Item>)}
              </FormDropdown>

              <FormApiSearchableDropdown
                label={"Linked account"}
                value={formData.account}
                fetchApi={api.accounts.get}
                labelKey={"company_name"}
                onSelect={(e) => formDataChangeHandler('account', e)}
              />

              {SettingsCmpClass && <SettingsCmpClass data={formData.settings} setFormData={setSettingsFormData}/>}
            </Form>
          </div>
          <div style={{ float: 'left' }}>
            <div style={{ display: 'inline-block', marginTop: '0.5rem', marginRight: '1rem', marginBottom: '3rem' }}>
              <ActionButton label="Save" onClick={handleSubmit} />
            </div>
            {!isNewTemplate && (
              <div style={{ display: 'inline-block', marginTop: '0.5rem', marginBottom: '3rem' }}>
                <ActionButton label="Download" onClick={handleDownload} />
              </div>
            )}
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <DropZone maxFilesPerDrop={1} maxFilesTotalCount={1} onChange={handleFileChange} acceptedFileTypes={[FILE_TYPE.Excel]} />
        </div>
      </div>
    </>
  );
}

export default ImportTemplateForm;
